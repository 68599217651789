<template>
  <DialogFormWrapper>
    <template #form>
      <InvoicePreview
        v-if="isPreviewMode"
        :preview="preview"
      />
      <v-form
        v-else
        id="correctInvoiceForm"
        ref="correctInvoiceForm"
        class="custom-form pt-8"
        @submit.prevent="sumbitCorrectionForm"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="correctionType"
              :items="correctionTypes"
              outlined
              label="Typ korekty"
              dense
              @input="getRawInvoiceData"
            />
          </v-col>
        </v-row>

        <v-row v-if="correctionType === 'Dane'">
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nabywca"
              v-model="invoiceData.buyerName"
              :rules="[rules.required]"
              placeholder="Wpisz nazwę nabywcy"
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              outlined
              label="Adres"
              v-model="invoiceData.address"
              validate-on-blur
              :rules="[rules.required]"
              placeholder="Wpisz ulicę i nr lokalu"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              label="Nr NIP"
              v-model="invoiceData.nip"
              :rules="[rules.required, rules.nipNumber]"
              placeholder="Wpisz numer NIP"
              validate-on-blur
              v-mask="'##########'"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              label="Kod pocztowy"
              v-model="invoiceData.postalCode"
              :rules="[rules.required, rules.postCode]"
              validate-on-blur
              placeholder="xx-xxx"
              v-mask="getFieldMask('postCode')"
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              outlined
              label="Miejscowość"
              v-model="invoiceData.city"
              :rules="[rules.required]"
              placeholder="Wpisz nazwę miejscowości"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="invoiceData.notes"
              label="Uwagi"
              rows="2"
              placeholder="Uwagi dotyczące dokumentu"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <template v-else>
          <v-row
            v-for="invoiceItem in invoiceItems"
            :key="invoiceItem.id"
          >
            <v-col cols="7">
              <v-text-field
                outlined
                label="Nazwa usługi"
                class="invoice-item-name"
                :value="invoiceItem.name"
                readonly
                disabled
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                outlined
                label="Cena netto [zł]"
                v-model="invoiceItem.netValue"
                :rules="[
                  rules.required,
                  rules.money,
                  rules.lowerThan(10000001)
                ]"
                :disabled="correctionType === 'Ilość'"
                validate-on-blur
                placeholder="Wpisz wartość netto usługi [PLN]"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                label="Ilość"
                v-model.number="invoiceItem.quantity"
                :rules="[
                  rules.number,
                  rules.greaterThan(0),
                  rules.lowerThan(101)
                ]"
                :disabled="correctionType === 'Cena'"
                type="number"
                validate-on-blur
                placeholder="1"
              />
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="12">
            <ForwardDocument
              :item="{contactIds, externalContactEmails}"
              @update="updateContacts"
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0 pl-4 mb-0 mt-n3"
            v-if="!contactIds.length"
          >
            <div class="error--text">
              Faktura nie zostanie wysłana
            </div>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover mr-4"
        :loading="isProcessing"
        @click="previewCorrection"
      >
        {{ isPreviewMode ? 'Ukryj podgląd' : 'Podgląd danych' }}
      </v-btn>
      <v-btn
        color="primary"
        class="base-hover"
        type="submit"
        form="correctInvoiceForm"
        :loading="isProcessing"
      >
        Wystaw korektę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import InvoicePreview from './Partials/InvoicePreview'
import ForwardDocument from '../../components/Forms/Partials/ForwardDocument'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import rules from '../../utils/validators'
import { getFieldMask, parseAsBasicUnit } from '../../utils'
import { mapState, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    DialogFormWrapper,
    InvoicePreview,
    ForwardDocument,
  },
  mixins: [afterFormSubmitted],
  data: () => ({
    correctionTypes: ['Dane', 'Ilość', 'Cena'],
    correctionType: 'Dane',
    invoiceItems: [],
    invoiceData: {},
    isPreviewMode: false,
    preview: {},
    contactIds: [],
    externalContactEmails: [],
    rules
  }),
  computed: {
    ...mapState({
      isProcessing: state => state.invoice.processing,
      invoice: state => state.layout.dialog.item,
      clientContacts: state => state.client.entity?.contacts,
    }),
    clientContactsWithEmail() {
      return this.clientContacts?.filter(contact => !!contact.email) || []
    },
  },
  created() {
    const clientId = this.invoice?.orders?.[0].client?.id || this.invoice.clientId
    if (clientId) {
      this.getClient(clientId).then(() => {
        this.contactIds = this.clientContactsWithEmail
          .map(contact => contact.invoiceReceivable && contact.id)
          .filter(id => !!id)
      })
    }
    this.getRawInvoiceData()
  },
  methods: {
    ...mapActions({
      correctInvoice: 'invoice/correctInvoice',
      getCorrectionPreview: 'invoice/getCorrectionPreview',
      getClient: 'client/getSingleClient',
    }),
    updateContacts({ contactIds, externalContactEmails }) {
      this.contactIds = contactIds
      this.externalContactEmails = externalContactEmails
    },

    getRawInvoiceData() {
      const { invoiceItems, buyerName, city, nip, address, postalCode, notes } = cloneDeep(this.invoice)
      const items = invoiceItems.map(item => {
        const { netValue, ...rest } = item
        return {
          netValue: netValue / 100,
          ...rest
        }
      })
      this.invoiceItems = [...items]
      this.invoiceData = { buyerName, city, nip, address, postalCode, notes }
    },

    getFieldMask,

    collectParams() {
      let params = {
        correctionType: this.correctionType,
        contactIds: this.contactIds,
      }
      if (this.correctionType === 'Dane') {
        params = {
          ...params,
          ...this.invoiceData
        }
      } else if (this.correctionType === 'Ilość') {
        params.invoiceItems = this.invoiceItems.map(item => ({
          id: item.id,
          quantity: item.quantity || 0
        }))
      } else if (this.correctionType === 'Cena') {
        params.invoiceItems = this.invoiceItems.map(item => ({
          id: item.id,
          netValue: parseAsBasicUnit(item.netValue)
        }))
      }
      return params
    },

    previewCorrection() {
      this.isPreviewMode = !this.isPreviewMode

      const params = this.collectParams()
      this.getCorrectionPreview({ params, id: this.invoice.id })
        .then(invoice => {
          this.preview = invoice
        })
    },

    sumbitCorrectionForm() {
      if (this.$refs.correctInvoiceForm.validate()) {
        const params = this.collectParams()

        this.correctInvoice({ params, id: this.invoice.id })
          .then(() => { this.afterFormSubmitted('Wystawiono korektę do faktury') })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-item-name::v-deep input {
  color: black !important;
}
</style>
