<template>
  <v-row>
    <v-col
      cols="12"
      class="text-h56 font-weight-bold black--text"
    >
      Podgląd danych:
    </v-col>
    <v-col
      v-for="field in parsedPreview"
      :key="field.name"
      :cols="field.col"
    >
      <div class="input-label">
        {{ field.text }}
      </div>
      <div class="input-value">
        {{ field.value }}
      </div>
    </v-col>
    <v-col cols="12">
      <DetailsSectionInvoiceItems
        :data="preview"
        :detail="invoiceItemsHeaders"
      />
    </v-col>
  </v-row>
</template>

<script>
import DetailsSectionInvoiceItems from '../../../components/Single/Sections/DetailsSectionInvoiceItems'
import { formatValue } from '../../../utils/typesEnum'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { getTableDetails } from '../../../utils'

const previewConfig = [
  { name: 'invoiceNumber', text: 'Nr faktury', value: 'invoiceNumber', col: 4 },
  { name: 'buyerName', text: 'Nabywca', value: 'buyerName', col: 8 },
  { name: 'nip', text: 'Nr NIP', value: 'nip', col: 3 },
  { name: 'address', text: 'Adres', value: 'address', col: 3 },
  { name: 'city', text: 'Miejscowość', value: 'city', col: 3 },
  { name: 'issueDate', text: 'Data sprzedaży', value: 'issueDate', col: 3 },
  { name: 'postalCode', text: 'Kod pocztowy', value: 'postalCode', col: 3 },
  { name: 'paymentType', text: 'Typ płatności', value: 'paymentType', col: 3 },
  { name: 'paymentStatus', text: 'Status płatności', value: 'paymentStatus', col: 3 },
  { name: 'netValue', text: 'Cena netto', value: 'netValue', col: 3 },
  { name: 'grossValue', text: 'Cena brutto', value: 'grossValue', col: 3 },
  { name: 'notes', text: 'Uwagi', value: 'notes', col: 9 }
]

export default {
  props: {
    preview: {
      type: Object,
      required: true
    }
  },

  components: {
    DetailsSectionInvoiceItems,
  },

  computed: {
    parsedPreview() {
      const parsePreview = preview => {
        return previewConfig.map(field => {
          const value = get(preview, field.value)
          return {
            ...field,
            value: formatValue(value, field.name)
          }
        })
      }
      return isEmpty(this.preview) ? false : parsePreview(this.preview)
    },
    invoiceItemsHeaders() {
      return getTableDetails('invoices')?.sections.find(
        section => section.name === 'items'
      )
    },
  }
}
</script>
